<template>
  <div id="employee-new-modal">
    <vs-popup class="popup90" :title="title" :active.sync="localShow">

      <div class="flex items-start flex-col lg:flex-row">
        <div class="w-full">
          <div class="vx-row">
            <div class="vx-col md:w-1/4 w-full mt-0">
              <vs-input class="w-full mt-0" label="Marca" v-model="vehicle.brand" data-vv-as="Marca" v-validate.initial="'required'" name="brand" />
              <span class="text-danger text-sm" v-show="errors.has('brand')">{{ errors.first('brand') }}</span>
            </div>
            <div class="vx-col md:w-1/4 w-full mt-0">
              <vs-input class="w-full mt-0" label="Modelo" v-model="vehicle.model" data-vv-as="Modelo" v-validate.initial="'required'" name="model" />
              <span class="text-danger text-sm" v-show="errors.has('model')">{{ errors.first('model') }}</span>
            </div>
            <div class="vx-col md:w-1/4 w-full mt-0">
              <vs-input class="w-full mt-0" label="Cor" v-model="vehicle.color" data-vv-as="Cor" v-validate.initial="'required'" name="color" />
              <span class="text-danger text-sm" v-show="errors.has('color')">{{ errors.first('color') }}</span>
            </div>
            <div class="vx-col md:w-1/4 w-full mt-0">
              <label class="vs-input--label">Tipo</label>
              <v-select v-model="vehicle.type" :reduce="option => option.value"
                :clearable="false" :options="[
                  {value: 'CAR', label: 'Automóvel'},
                  {value: 'MOTORCYCLE', label: 'Moto'},
                  {value: 'TRUCK', label: 'Caminhão'},
                  {value: 'BUS', label: 'Ônibus'}
                ]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Desculpe, nenhum resultado encontrado.
                </template>
              </v-select>
            </div>
          </div>
        </div>
      </div>

      <div class="flex items-start flex-col lg:flex-row">
        <div class="w-full">
          <div class="vx-row">
            <div class="vx-col md:w-1/4 w-full mt-0">
              <vs-input type="number" class="w-full mt-0" label="Ano Fabricação" v-model="vehicle.year_of_manufacture" data-vv-as="Ano Fabricação" v-validate.initial="'required'" name="year_of_manufacture" />
              <span class="text-danger text-sm" v-show="errors.has('year_of_manufacture')">{{ errors.first('year_of_manufacture') }}</span>
            </div>
            <div class="vx-col md:w-1/4 w-full mt-0">
              <vs-input type="number" class="w-full mt-0" label="Ano do Modelo" v-model="vehicle.year_of_model" data-vv-as="Ano do Modelo" v-validate.initial="'required'" name="year_of_model" />
              <span class="text-danger text-sm" v-show="errors.has('year_of_model')">{{ errors.first('year_of_model') }}</span>
            </div>
            <div class="vx-col md:w-1/4 w-full mt-0">
              <vs-input class="w-full mt-0" label="Placa" v-model="vehicle.license_plate" data-vv-as="Placa" v-validate.initial="'required'" name="license_plate" />
              <span class="text-danger text-sm" v-show="errors.has('license_plate')">{{ errors.first('license_plate') }}</span>
            </div>
            <div class="vx-col md:w-1/4 w-full mt-0">
              <vs-input class="w-full mt-0" label="RENAVAM" v-model="vehicle.renavam" data-vv-as="RENAVAM" v-validate.initial="'required'" name="renavam" />
              <span class="text-danger text-sm" v-show="errors.has('renavam')">{{ errors.first('renavam') }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="flex items-start flex-col lg:flex-row">
        <div class="w-full">
          <div class="vx-row">
            <div class="vx-col md:w-1/4 w-full mt-0">
              <label class="vs-input--label">Categoria</label>
              <v-select v-model="vehicle.category" :reduce="option => option.value" data-vv-as="Categoria" v-validate.initial="'required'" name="category"
                :clearable="false" :options="[
                  {value: 'A',   label: 'A'},
                  {value: 'B',   label: 'B'},
                  {value: 'C',   label: 'C'},
                  {value: 'D',   label: 'D'},
                  {value: 'E',   label: 'E'},
                  {value: 'ACC', label: 'ACC'},
                  {value: 'X',   label: 'X'}
                ]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Desculpe, nenhum resultado encontrado.
                </template>
              </v-select>
              <span class="text-danger text-sm" v-show="errors.has('category')">{{ errors.first('category') }}</span>
            </div>
            <div class="vx-col md:w-1/4 w-full mt-0">
              <label class="vs-input--label">Vcmto IPVA</label>
              <v-select class="vue_select_drop_size_160" v-model="vehicle.ipva_expiration" :reduce="option => option.value"
                :clearable="false" :options="[
                  {value: '1',  label: 'JANEIRO'},
                  {value: '2',  label: 'FEVEREIRO'},
                  {value: '3',  label: 'MARÇO'},
                  {value: '4',  label: 'ABRIL'},
                  {value: '5',  label: 'MAIO'},
                  {value: '6',  label: 'JUNHO'},
                  {value: '7',  label: 'JULHO'},
                  {value: '8',  label: 'AGOSTO'},
                  {value: '9',  label: 'SETEMBRO'},
                  {value: '10', label: 'OUTUBRO'},
                  {value: '11', label: 'NOVEMBRO'},
                  {value: '12', label: 'DEZEMBRO'}
                ]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Desculpe, nenhum resultado encontrado.
                </template>
              </v-select>
            </div>
            <div class="vx-col md:w-1/4 w-full mt-0">
              <label class="vs-input--label">Adaptado</label>
              <v-select v-model="vehicle.adapted" :reduce="option => option.value"
                :clearable="false" :options="[
                  {value: false,  label: 'NÃO'},
                  {value: true,  label: 'SIM'},
                ]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Desculpe, nenhum resultado encontrado.
                </template>
              </v-select>
            </div>
            <div class="vx-col md:w-1/4 w-full mt-0">
              <label class="vs-input--label">Vincular Instrutor(es)</label>
              <v-select v-model="vehicle.employees" multiple :reduce="option => option.value" :options="employees" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Desculpe, nenhum resultado encontrado.
                </template>
              </v-select>
            </div>
          </div>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-full w-full">
          <vs-input class="w-full mt-2" label="Observações" v-model="vehicle.observation" />
        </div>
      </div>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" @click="save" :disabled="!validateForm">Cadastrar</vs-button>
          <vs-button class="ml-4 mt-2" type="border" color="secondary" @click="cancel">Cancelar</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from 'vue-select'

import moduleEmployee       from '@/store/employee/moduleEmployee.js'

export default {
  name: 'new-medical-modal',

  components: {
    vSelect
  },
  props: {
    show: {
      Boolean, default: false
    },
    title: {
      String, default: 'Diálogo'
    }
  },

  computed: {
    employees () {
      return this.$store.getters['employee/forSelect']
    },
    validateForm () {
      return !this.errors.any()
    }
  },

  data () {
    return {
      localShow: false,
      vehicle: {
        brand: '',
        model: '',
        color: '',
        type: 'CAR',
        year_of_manufacture: '',
        year_of_model: '',
        license_plate: '',
        renavam: '',
        category: '',
        ipva_expiration: '',
        adapted: false,
        employees: [],
        observation: null
      }
    }
  },

  watch: {
    show (val) {
      this.localShow = val
    },
    localShow (val) {
      if (!val) {
        this.vehicle = {
          brand: '',
          model: '',
          color: '',
          type: 'CAR',
          year_of_manufacture: '',
          year_of_model: '',
          license_plate: '',
          renavam: '',
          category: '',
          ipva_expiration: '',
          adapted: false,
          employees: [],
          observation: null
        }
        this.$emit('cancel')
      }
    }
  },

  methods: {
    action () {
      this.$emit('action')
    },
    cancel () {
      this.$emit('cancel')
    },
    async save () {
      try {
        this.$vs.loading()
        await this.$store.dispatch('vehicle/store', this.vehicle)
        this.$vs.loading.close()
        this.action()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
        this.localShow = false
      } catch (error) {
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    }
  },
  created () {
    if (!moduleEmployee.isRegistered) {
      this.$store.registerModule('employee', moduleEmployee)
      moduleEmployee.isRegistered = true
    }
    this.$store.dispatch('employee/fetchAll').then(() => { }).catch(err => { console.error(err) })
  }
}
</script>

<style>
  hr {
    margin-top: 2px;
    border: none;
    height: 1px;
    /* Set the hr color */
    color: rgb(204, 204, 204); /* old IE */
    background-color: rgb(204, 204, 204); /* Modern Browsers */
  }

  .popup90 .vs-popup {
    width: 90% !important;
  }

  .vue_select_drop_size_160 .vs__dropdown-menu {
    max-height: 160px;
  }
</style>
